import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import GitHubIcon from '@mui/icons-material/GitHub';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import XIcon from '@mui/icons-material/X';
import { Box, IconButton, Link, Stack, Typography } from '@mui/material';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
        spacing={2}
      >
        <img
          draggable="false"
          src="/logo/zktx.svg"
          alt="zktx.id"
          title="zktx.id"
        />
        <Box>
          <TypeAnimation
            sequence={[
              '',
              1000,
              'Hello, World!',
              5000,
              'Transition from web2 to web3 in Minutes.',
              5000,
              'zktx.io',
              5000,
            ]}
            wrapper="span"
            speed={50}
            style={{
              fontSize: '1em',
              display: 'inline-block',
              color: 'white',
              fontFamily: 'Roboto',
              fontWeight: 400,
            }}
            repeat={Infinity}
          />
        </Box>
        <Box>
          <IconButton
            component={Link}
            href="https://github.com/zktx-io"
            aria-label="github"
          >
            <GitHubIcon />
          </IconButton>
          <IconButton
            component={Link}
            href="https://twitter.com/zktx_io"
            aria-label="x"
          >
            <XIcon />
          </IconButton>
          <IconButton
            component={Link}
            href="https://docs.zktx.io"
            aria-label="docs.zktx.io"
          >
            <WidgetsOutlinedIcon />
          </IconButton>
          <IconButton
            component={Link}
            href="https://forum.zktx.io"
            aria-label="forum.zktx.io"
          >
            <ForumOutlinedIcon />
          </IconButton>
        </Box>
      </Stack>
    ),
  },
  {
    path: '*',
    element: <Navigate replace to="/" />,
  },
]);

function App() {
  return (
    <div className="App">
      <Box
        sx={{
          position: 'absolute',
          bottom: '10px',
          width: '100%',
          paddingX: 2,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h1"
          textAlign="right"
          sx={{ fontSize: { xs: '10px', sm: '12px' } }}
        >
          Journey into web3
        </Typography>
        <Typography
          variant="h2"
          textAlign="right"
          sx={{ fontSize: { xs: '10px', sm: '12px' } }}
        >
          Copyright © 2024 zktx.io
        </Typography>
      </Box>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
